.home-container {
  padding: 15px;
  text-align: center;

  h1 {
    margin: 15px 0;
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }

  li {
    padding: 20px 0 30px 0;
  }

  .icon-note,
  .icon-travelbook {
    color: grey;
  }
  .icon-step {
    color: #036da1;
  }
}

.menu-button {
  background: transparent;
  border: none;
}

.form-container {
  padding: 15px;
  width: 100%;
  margin: 0;

  h4 {
    margin-top: 10px;
    margin-bottom: 25px;
  }

  fieldset {
    border: solid 1px lightgray;
    padding: 10px;
  }

  .menu-title {
    padding: 10px 0 0 0;
  }

  .image-container img {
    max-width: 256px;
    max-height: 192px;
    padding: 10px 0 0;
    width: 100%;
  }

  input[type="color"] {
    margin: 0;
    border: none;
    padding: 2px 0;
  }

  .label-caption {
    margin-top: 5px;
  }

  /* bouton gestion de la localisation */
  .menu-button {
    padding: 0 0.25rem;
    margin: -4px 0 0 0;

    .icon-location {
      font-size: 1.8rem;
      color: black;
    }
  }

  label {
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .islast-container {
    margin: 15px 0 5px 0;
  }
  .visibility-container {
    margin: 5px 0 15px 0;
  }

  .requis {
    color: #df0b0b;
    font-weight: bold;
  }
  .btn-primary {
    background-color: #840505;
    border-color: #df0b0b;
  }
}

.creanote-container,
.visibility-container {
  margin: 15px 0;
}

.msgerr {
  margin: 15px 0;
  color: #cc0000;
}

.toggle {
  display: flex;
  justify-content: center;
}

.item-left {
  margin-right: 0.8em;
}
.item-right {
  margin-left: 0.8em;
}
.item {
  margin-top: -0.25em;
}

.circle {
  border: 0.1em solid grey;
  border-radius: 100%;
  height: 2.2em;
  width: 2.2em;
  text-align: center;
}

.circle p {
  margin-top: -0.1em;
  font-size: 1.5em;
  font-weight: bold;
  font-family: sans-serif;
  color: grey;
}

.fa-toggle-on,
.fa-toggle-off {
  color: #036da1;
  border-color: #036da1;
  font-size: 2em;
}

.last {
  color: green;
  font-size: 1rem;
  font-weight: 500;
}

.logout {
  float: right;
  background: transparent;
  border: none;
}
.logout-icon {
  font-size: 1.3em;
  color: lightgrey;
}
